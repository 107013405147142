import React, { useState, useEffect } from 'react';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { path } from 'ramda';

import ErrorCatch from './ErrorCatch';
import Landing from './Landing';
import Modal from './Modal';
import StatusModal from './StatusModal';
import { errorModal } from '../constants/Modal';
import { DOMAIN } from '../constants/apollo';
import { getVacancy } from '../utils/modal';
import { client, vacanciesQuery } from '../utils/apollo';
import { scrollToAnchor } from '../utils/anchors';

const App = () => {
    const { loading, error, data, refetch } = useQuery(vacanciesQuery, {
        variables: { domain: DOMAIN },
    });

    const vacancies = path(['vacancies', 'items'], data);

    const [modalIsOpen, openModal] = useState(getVacancy());
    const [statusModal, openStatusModal] = useState(false);

    useEffect(() => {
        scrollToAnchor();

        const handler = () => {
            openModal(getVacancy());
        };

        window.addEventListener('hashchange', handler);

        return () => window.removeEventListener('hashchange', handler);
    });

    const closeModal = () => {
        window.history.pushState('', document.title, window.location.pathname + window.location.search);
        openModal(null);
    };

    const hidden = statusModal === errorModal;

    return (
        <ErrorCatch>
            { !loading ? (
                <Landing
                    openModal={openModal}
                    openStatusModal={openStatusModal}
                    vacancies={vacancies}
                    vacanciesError={error}
                    vacanciesRefresh={refetch}
                />
            ) : null}
            <Modal
                openStatusModal={openStatusModal}
                open={modalIsOpen}
                closeModal={closeModal}
                hidden={hidden}
                vacancies={vacancies}
            />
            <StatusModal
                statusModal={statusModal}
                openStatusModal={openStatusModal}
            />
        </ErrorCatch>
    );
}

export default () => (
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
);
