import 'core-js/es/string/starts-with';
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/number/is-nan';
import 'react-app-polyfill/ie9';

import React from 'react';
import { hydrate, render } from 'react-dom';

import './styles/index.scss';
import rollbarInit from './utils/rollbar';
import { setBaseUrl } from './utils/axios';
import App from './components/App';

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: '8f47b71c320b4e5d907f062cd3eba1fc',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });


    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

setBaseUrl();

const root = document.getElementById('root');

if (root.hasChildNodes()) {
    hydrate(<App />, root);
} else {
    render(<App />, root);
}
