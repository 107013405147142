import React, { useRef } from 'react';
import Slider from "react-slick";
import { path } from 'ramda';

import Form from './Form';
import Header from './Header';

import ancor from '../assets/images/ancor.svg';
import ruPort from '../assets/images/ru_port.svg';
import slide1 from '../assets/images/slider/1.png';
import aboutSlide1 from '../assets/images/about-slider/1.png';
import aboutSlide2 from '../assets/images/about-slider/2.png';
import aboutSlide3 from '../assets/images/about-slider/3.png';
import opd from '../assets/opd.pdf'

import { openVacancyModal } from '../utils/metrics';
import { changeLocationHash } from '../utils/modal';

const slide = [aboutSlide1, aboutSlide2, aboutSlide3];

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
};

const settingsAbout = {
    customPaging: function(i) {
        return (
            <span>
                <img src={slide[i]} alt=''/>
            </span>
        );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const Landing = ({ openModal, openStatusModal, vacancies, vacanciesError, vacanciesRefresh }) => {
    const slider = useRef(null);

    const next = () => {
        slider.current.slickNext();
    };

    const previous = () => {
        slider.current.slickPrev();
    };

    return (
        <div className="app">
            <Header />
            <div className="container">
                <div className="slider-text">
                    <h1>Работа для врачей</h1>
                </div>
            </div>
            <Slider className="header-slider" {...settings}>
                <div>
                    <img src={slide1} alt="Работа для врачей" />
                </div>
            </Slider>
            <div className="box-1">
                <div className="container">
                    <div className="program">
                        <div className="row">
                            <div className="col-6">
                                <b>Вакансии для медиков от крупнейшего кадрового холдинга страны ANCOR</b>
                            </div>
                            <div className="col-6 bg" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <section className="box-2" id="program">
                    <div className="container">
                        <p>В России большой спрос на квалифицированный медперсонал. Не меньше и клиник — как частных, так и государственных, — готовых предложить медикам достойные условия. Мы просто помогаем медикам найти лучшую работу, а медучреждениям — собрать эффективные команды из профессионалов, влюблённых в своё дело.</p>
                    </div>
                </section>
                <section className="box-3">
                    <div className="container">
                        <h2>MED.ANCOR в цифрах</h2>
                        <div className="row">
                            <div className="col-4">
                                <h4>30</h4>
                                <p>лет <br/>на рынке труда</p>
                            </div>
                            <div className="col-4">
                                <h4>70</h4>
                                <p>российских <br/>медучреждений</p>
                            </div>
                            <div className="col-4">
                                <h4>19</h4>
                                <p>регионов <br/>России</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="box-4" id="about">
                    <div className="container">
                        <h2 className="mobile">Где нужен <br/>медперсонал</h2>
                    </div>
                    <Slider className="about-slider" ref={slider} {...settingsAbout}>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide1} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Вакансии в Подмосковье</h3>
                                        <p>Вакансии для медиков в крупном многопрофильном медицинском центре в Подмосковье со 100% компенсацией жилья.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide2} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Вакансии на Сахалине</h3>
                                        <p>Море вакансий и целый остров возможностей для медиков со всей России.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-slider">
                            <div className="row">
                                <div className="col-6">
                                    <div className="img-slider">
                                        <img src={aboutSlide3} alt="slide" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="text-slider">
                                        <h3>Вакансии во Владимире</h3>
                                        <p>Вакансии для врачей в частной клинике города Владимира. В качестве бонуса — компенсация затрат на дополнительное обучение и жильё.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="title-slider">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Где нужен <br/>медперсонал</h2>
                                </div>
                                <div className="col-6">
                                    <div className="btn-slider">
                                        <button className="button prev" onClick={previous} />
                                        <button className="button next" onClick={next} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="box-5" id="vacancy">
                    <div className="container">
                        <h2>Кого мы ищем </h2>
                        <div className="row list-vacancy">
                            { vacancies ? vacancies.map(({ title, code, cities }, index) => (
                                <div className="col-3" key={index}>
                                    <div className="vacancy" onClick={() => {
                                        changeLocationHash(code);
                                        openVacancyModal(code);
                                        openModal(code);
                                    }}>
                                        <a href={`#/${code}`}>
                                            <div className="name">
                                                <span>{title}</span>
                                            </div>
                                        </a>
                                        <div className="sum">
                                            { path([0, 'name'], cities) }
                                        </div>
                                    </div>
                                </div>
                            )) : vacanciesError ? (
                                <div className="col-12 text-center">
                                    <p>Не удалось загрузить список вакансий</p>
                                    <button className="btn" onClick={() => vacanciesRefresh()}>
                                        Обновить
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </section>

                <div id="footer">
                    <section id="form" className="box-6">
                        <div id="formContainer" className="container">
                            <div className="row">
                                <div className="col-6 bg" />
                                <div className="col-6">
                                    <h3>Заполните анкету, и мы свяжемся с вами</h3>
                                    <Form
                                        openModal={openModal}
                                        openStatusModal={openStatusModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box-7">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Отправляйте резюме и узнавайте подробности</h3>
                                </div>
                                <div className="col-6 contact">
                                    <div>
                                        <a href="tel:88007006807" className="phone">8 800 700 68 07</a>
                                    </div>
                                    <a href="mailto:info@med.ancor.ru" className="email">info@med.ancor.ru</a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="box-social">
                        <div className="container">
                            <a href="https://vk.com/sakh_medicina" className='social vk'><span className="icon" /></a>
                            <a href="https://www.instagram.com/med.ancor/" className='social inst'><span className="icon" /></a>
                        </div>
                    </div>

                    <footer>
                        <div className="container">
                            <img src={ancor} className="ancor" alt="ancor" />
                            <span className="pdf">
                                <a href={opd}>Политика обработки персональных данных</a>
                            </span>
                            <img src={ruPort} className="ru-port" alt="ru_port" />
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Landing);
