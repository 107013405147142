import React from 'react';
import ReactModal from 'react-modal';
import { find, propEq } from 'ramda';

import Form from './Form';
import Money from './common/Money';

const vacancyModalStyle = (error) => ({
    content : {
        top                   : '0',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, 0)',
        width                 : '1024px',
        zIndex                : '1000',
        marginBottom          : '20px',
        padding               : '0',
        overflow              : 'hidden',
        borderRadius          : '0',
        background            : 'transparent',
        border                : '0',
        display               : error ? 'none' : 'block',
    },
    overlay: {
        backgroundColor       : 'rgba(0, 0, 0, 0.40)',
        zIndex                : '1000',
        overflowY             : 'auto',
        overflowX             : 'hidden',
        display               : error ? 'none' : 'block',
    }
});

ReactModal.setAppElement('#root');

const Modal = ({ open, hidden, closeModal, openStatusModal, vacancies = [] }) => {
    const vacancy = find(propEq('code', open), vacancies) || {};

    return (
        <ReactModal
            isOpen={!!open}
            onRequestClose={closeModal}
            style={vacancyModalStyle(hidden)}
        >
            <div className='vacancy-modal'>
                <div onClick={closeModal} className='vacancy-modal-close-btn' />
                <h3 className='vacancy-title'>
                    { vacancy.title }
                </h3>
                <div className='vacancy-salary'>
                    <b>Зарплата:</b>
                    <Money min={vacancy.minSalary} max={vacancy.maxSalary} />
                </div>
                { vacancy.description && (
                    <div className='vacancy-description' dangerouslySetInnerHTML={{__html: vacancy.description}} />
                )}
                <Form
                    initialValues={{ desiredPosition: open }}
                    isModal={true}
                    closeModal={closeModal}
                    openStatusModal={openStatusModal}
                />
            </div>
        </ReactModal>
    );
}

export default Modal;
