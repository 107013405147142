import axios from 'axios';

import { BASE_URL, LANDING_ID } from '../constants/axios';

export function setBaseUrl(base = BASE_URL) {
    axios.defaults.baseURL = base;
}

export function postVacancyResponse(payload) {
    return axios.post(`/v1/landing/vacancy/${LANDING_ID}`, payload);
}
