import { contains } from 'ramda';

import { ANCHORS } from '../constants/anchors';

export const scrollToAnchor = () => {
    const anchor = window.location.hash;

    if (contains(anchor, ANCHORS)) {
        const el = document.getElementById(anchor.replace('#', ''));

        if (el) {
            setTimeout(() => window.scrollTo({
                top: el.getBoundingClientRect().y + window.pageYOffset,
                behavior: 'smooth'
            }), 200);
        }
    }
}
