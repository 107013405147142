import React from 'react';

import { pushRollbarError } from '../utils/rollbar';

export default class ErrorCatch extends React.Component {
    state = {
        hasError: false
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        pushRollbarError(error, info);
    }

    render() {
      if (this.state.hasError) {
            return <h1>Ошибка! Не удалось запустить приложение.</h1>;
      }

      return this.props.children;
    }
}
