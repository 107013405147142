export const sendForm = () => {
    window.ym(window.YT, 'reachGoal', 'send_form');

    window.gtag('event',
        'send',
        {
            event_category: 'form',
            event_label: 'complete',
        }
    );
};

export const sendFormVak = () => {
    window.ym(window.YT, 'reachGoal', 'send_form_vak');

    window.gtag('event',
        'send',
        {
            event_category: 'vak',
            event_label: 'complete',
        }
    );
};

export const openVacancyModal = (label) => {
    const ymTarget = label === 'diag' ? 'open_form_labdiag' : 'open_form_' + label;

    window.ym(window.YT, 'reachGoal', ymTarget);

    window.gtag('event',
        'form',
        {
            event_category: 'open',
            event_label: label,
        }
    );
};

