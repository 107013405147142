import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
const GQL_URL = require('../constants/urls').GQL_URL;

const uri = process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap" ? '/api/graphql' : `${GQL_URL}/api/graphql`;

export const client = new ApolloClient({
    uri,
});

export const vacanciesQuery = gql(`
    query getVacanciesByDomain($domain: String!) {
        vacancies(
            filter: { domainName: $domain, sliderPublished: true },
            pagination: { limit: 100 },
            sorting: { custom : { datePublishedStart: -1, _id: -1 } }
        ) {
            items {
                id,
                code,
                title,
                description,
                minSalary,
                maxSalary,
                datePublishedStart,
                cities {
                    name
                }
            }
        }
    }
`);
