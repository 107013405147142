import React from 'react';
import ReactModal from 'react-modal';

import { successModal, errorModal } from '../constants/Modal';
import success from '../assets/images/success.svg';
import error from '../assets/images/error.svg';

const statusModalStyle = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, -50%)',
        width                 : '650px',
        zIndex                : '1000',
        padding               : '0',
        overflow              : 'hidden',
        borderRadius          : '0',
    },
    overlay: {
        backgroundColor       : 'rgba(0, 0, 0, 0.40)',
        zIndex                : '1000',
        overflowY             : 'auto',
        overflowX             : 'hidden',
    }
};

ReactModal.setAppElement('#root');

const StatusModal = ({ statusModal, openStatusModal }) => {
    const isError = statusModal === errorModal;
    const isSuccess = statusModal === successModal;
    const closeModal = () => openStatusModal(null)

    return (
        <ReactModal
            isOpen={isError || isSuccess}
            onRequestClose={closeModal}
            style={statusModalStyle}
        >
                 <div className='submit-modal'>
                    <div>
                        { isSuccess ? (
                            <div>
                                <img src={success} className="icon-status" alt="success" />
                                <h4>Заявка отправлена!</h4>
                                <p>В течение суток с вами свяжется наш специалист, и расскажет подробные условия программы</p>
                                <button onClick={closeModal} className='btn'>Я понял</button>
                            </div>
                        ) : (
                            <div>
                                <img src={error} className="icon-status" alt="error" />
                                <h4>Что-то пошло не так</h4>
                                <p>Попробуйте отправить заявку<br/> через некоторое время</p>
                                <button onClick={closeModal} className='btn'>Я понял</button>
                            </div>
                        )}
                    </div>
                </div>
        </ReactModal>
    );
}

export default StatusModal;
