import { contains } from 'ramda';

import { ANCHORS } from '../constants/anchors';

export const getVacancy = () => {
    const vacancyHash = window.location.hash;

    if (vacancyHash && !contains(vacancyHash, ANCHORS)) {
        const vacancyKey = vacancyHash.replace(/[#/]/g, '');
        return vacancyKey;
    } else {
        return null;
    }
}

export const changeLocationHash = (vacancyKey) => {
    window.location.hash = `#/${vacancyKey}`;
};
