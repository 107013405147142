const requiredText = 'Это поле обязательно';

export const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = requiredText;
    }

    if (!values.specialty) {
        errors.specialty = requiredText;
    }

    if (!values.phone) {
        errors.phone = requiredText;
    } else {
        const clearPhone = values.phone.replace(/_|\+|-|\s|\(|\)/g, '');

        if (clearPhone.length < 11) {
            errors.phone = requiredText;
        }
    }

    if (!values.opd) {
        errors.opd = requiredText;
    }

    return errors;
}

export const  getRequestPayload = (values) => {
    const [lastName, ...firstName ] = values.name.trim().split(' ');

    let fields = [
        {
            name: 'fname',
            tableName: 'personal',
            value: firstName.join(' '),
        },
        {
            name: 'lname',
            tableName: 'personal',
            value: lastName,
        },
        {
            name: 'WORK_EXPERIENCE_CODE',
            tableName: 'GRADUATEINFO',
            value: '3',
        },
        {
            name: 'WORK_EXPERIENCE_OTHER',
            tableName: 'GRADUATEINFO',
            value: values.specialty,
        },
        {
            name: 'contact-phone',
            tableName: 'contact',
            value: values.phone,
        },
        {
            name: 'SoglasieOPD',
            tableName: 'GRADUATEINFO',
            value: values.opd,
        },
    ];

    if (values.additionalInfo) {
        fields.push({
            name: 'PERMISSIONS_TO_WORK',
            tableName: 'GRADUATEINFO',
            value: values.additionalInfo,
        });
    }

    if (values.desiredPosition) {
        fields.push({
            name: 'DESIRED_POSITION_STR',
            tableName: 'GRADUATEINFO',
            value: values.desiredPosition,
        });
    }

    return ({ fields });
}
